import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { UserProfile } from '../_interfaces/userprofile';
import { AuthService } from '../_services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserprofileService {

  public user: Observable<firebase.User>;
  userId: string;
  userProfileDoc: any;
  public userProfileRef: any;
  public userDeviceCount: number;
  public userDeviceId: string;


  constructor(
    public afAuth: AngularFireAuth,
    public authService: AuthService,
    public afs: AngularFirestore,
  ) {
    this.user = this.authService.user;
    // console.log(`this.user: ${this.user}`);
  } // closes constructor

  getUserProfileDocFormData(userId) {
    return this.afs.doc(`userprofile/${userId}`);
  }
  async getUserProfileDocRef(userId) {
    await this.afs.collection('userprofile').doc(userId).ref.get()
    .then( doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        this.userProfileRef = doc.data();
        // console.log(`userProfile: ${this.userProfileRef}`);
        this.userDeviceCount = this.userProfileRef.devices.length;
        this.userDeviceId = this.userProfileRef.devices[0];
        // console.log(this.userDeviceId);

      }
    })
    .catch(err => {
      console.log('Error getting document', err);
    });
  }


  updateUserProfileDoc(
    uid: string,
    // email: string,
    firstname: string,
    lastname: string,
    phone: number,
    street1: string,
    street2: string,
    city: string,
    state: string,
    zipcode: string,
    // devices: any
    ): Promise<void> {
    return this.afs.doc(`userprofile/${uid}`).set({
      uid,
      // email,
      firstname,
      lastname,
      phone,
      street1,
      street2,
      city,
      state,
      zipcode,
      // devices
    }, {merge: true});
  } // End Provider for UserProfile

  addDeviceToUserDevices(deviceId, userId) {
    this.afs.doc(`userprofile/${userId}`).set({
      devices : firebase.firestore.FieldValue.arrayUnion(deviceId)
    }, {merge: true});
  }
} // closes class
