import { Injectable } from '@angular/core';
// services
import { Ticket } from '../_interfaces/ticket';
import { Ticketcomment } from '../_interfaces/ticketcomment';
import { AuthService } from '../_services/auth.service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(
    public afs: AngularFirestore,
    public authService: AuthService,

  ) { }

     // Provider for Create Ticket
  createTicket(
    createdAt, modifiedAt, ticketStatus, userId, userEmail, ticketCategory, ticketSubject, ticketDescription
    ) {
    return this.afs.collection(`tickets`).add({
      // /${id}`).set({
        createdAt, modifiedAt, ticketStatus, userId, userEmail, ticketCategory, ticketSubject, ticketDescription
    });
  } // End Provider for Create Ticket

  getTicketList(userId): AngularFirestoreCollection<Ticket> {
    return this.afs.collection('tickets', ref => ref
      .where('userId', '==', userId)
      .orderBy('modifiedAt', 'desc'));
  }

  getTicketDetail(ticketId: string): AngularFirestoreDocument<Ticket[]> {
    return this.afs.doc(`tickets/${ticketId}`);
  }
  getTicketCommentsList(parentTicket): AngularFirestoreCollection<Ticketcomment> {
    return this.afs.collection('ticketcomments', ref => ref
      .where('parentTicketId', '==', parentTicket)
      .orderBy('createdAt', 'asc')
    );
  }
  createTicketComment(theParentTicketId, theUserEmail, theUserId, theTicketComment) {
    return this.afs.collection('ticketcomments').add({
      createdAt: Date.now(),
      userEmail: theUserEmail,
      userId: theUserId,
      parentTicketId: theParentTicketId,
      ticketComment: theTicketComment
    });
  }

  ticketDispositionClose(ticketId, theUserEmail, theUserId) {
    // should update the status of 'tickets' and update to 'ticketcomments'
    this.afs.collection('tickets').doc(ticketId).set({
      ticketStatus: 'Closed',
      modifiedAt: Date.now()
    }, { merge : true}).then( () => {
      const theTicketComment = `Ticket closed by ${theUserEmail}`;
      this.createTicketComment(ticketId, theUserEmail, theUserId, theTicketComment);
    });
  }

  ticketDispositionReOpen(ticketId, theUserEmail, theUserId) {
    // should update the status of 'tickets' and update to 'ticketcomments'
    this.afs.collection('tickets').doc(ticketId).set({
      ticketStatus: 'Open',
      modifiedAt: Date.now()
    }, { merge : true}).then( () => {
      const theTicketComment = `Ticket re-opened by ${theUserEmail}`;
      this.createTicketComment(ticketId, theUserEmail, theUserId, theTicketComment);
    });
  }


} // closes class
